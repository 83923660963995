















































// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FavoritesKeyEnum } from '@/models/Enums';

export enum MultiSelectModeEnum {
    Single = 1,
    Multiple = 2
}

interface IOption {
    ID: number;
    Name: string;
}

@Component
export default class MultiSelect extends Vue {
    @Prop() label!: string;
    @Prop() isLabelBold!: boolean;
    @Prop() small: boolean;
    @Prop() overrideDefaultOption: string;
    @Prop() selectionMode!: number;
    @Prop() canSelectAll: boolean;
    @Prop() favoritesKey: FavoritesKeyEnum;
    @Prop() options!: IOption[];
    @Prop() values!: number[];
    @Prop() disabledValues: number[];
    @Prop() isInError: number;

    IsDisplayingSelectModal: boolean = false;
    Options: IOption[] = [];;
    Filter: string = "";
    IsPopupOpen: boolean = false;

    created() {
        //If nothing was preselected, default to favorite if there is only one favorite
        if (this.values.length == 0 && this.Favorites.length == 1 && this.options.findIndex(o => { return o.ID == this.Favorites[0]; }) > -1) {
            this.UpdateValue(this.Favorites[0]);
        }
    }

    SortOptions() {
        this.Options = JSON.parse(JSON.stringify(this.options)) as IOption[];
        this.Options.sort((a, b) => {
            let aIsFav = this.IsFavoriteSelected(a.ID);
            let bIsFav = this.IsFavoriteSelected(b.ID);

            if (aIsFav == bIsFav) {
                return 0;
            } else if (aIsFav) {
                return -1;
            } else {
                return 1;
            }
        });
    }

    get HasFavoritesKey(): boolean {
        return this.favoritesKey != null;
    }

    get Favorites(): number[] {
        return this.HasFavoritesKey ? this.$store.getters["DevicePreferences/GetFavorites" + this.favoritesKey] : [];
    }

    get DisplaySelectedValues(): string {
        if (this.SelectedOptions.length == 0) {
            return this.overrideDefaultOption != null && this.overrideDefaultOption.length > 0 ? this.overrideDefaultOption : "Select " + this.label;
        } else {
            return this.options.find((option) => { return this.values.indexOf(option.ID) > -1; }).Name;
        }
    }

    get SelectedOptions(): IOption[] {
        return this.options.filter((option) => { return this.values.indexOf(option.ID) > -1; });
    }

    get IsMultiSelectMode(): boolean {
        return this.selectionMode == MultiSelectModeEnum.Multiple;
    }

    //The Options list filtered and sorted by favorites
    get DisplayOptions(): IOption[] {
        let lowerCaseFilter = this.Filter.toLowerCase();

        if (this.Filter.length > 1) {
            return this.Options.filter((option) => { return option.Name.toLowerCase().indexOf(lowerCaseFilter) > -1; });
        } else {
            return this.Options;
        }
    }

    IsSelected(value: number): boolean {
        return this.values.indexOf(value) > -1;
    }

    IsDisabledValue(value: number): boolean {
        return this.disabledValues != null && this.disabledValues.indexOf(value) > -1;
    }

    IsFavoriteSelected(value: number): boolean {
        return this.Favorites.indexOf(value) > -1;
    }

    ToggleSelectAll() {
        let newValues: number[] = this.values.slice(); //Copy values array

        //Rather than setting values to all options or to an empty list, we must go item
        //by item because (mostly for config pages) there may be values that don't have corresponding
        //options, and they must be preserved.
        if (this.SelectedOptions.length < this.options.length) {
            this.options.forEach((option) => {
                if (newValues.indexOf(option.ID) == -1) {
                    newValues.push(option.ID);
                }
            });
        } else {
            this.options.forEach((option) => {
                let itemIndex = newValues.indexOf(option.ID);
                if (itemIndex > -1) {
                    newValues.splice(itemIndex, 1);
                }
            });
        }

        this.$emit("update:values", newValues);
        this.$emit("Change"); //Useful for tacking on other callbacks
    }

    ToggleFavorite(value: number) {
        this.$store.commit('DevicePreferences/ToggleFavorites' + this.favoritesKey, value);
    }

    ToggleIsDisplayingSelectModal(isOpen: boolean) {
        if (isOpen) {
            this.SortOptions(); //Copy over master list of options and sort once by favorites
        }

        this.$emit("ModalToggled", isOpen);

        this.Filter = "";
        this.IsDisplayingSelectModal = isOpen;
    }

    UpdateValue(value: number) {
        if (this.IsDisabledValue(value)) { return; }

        let valueIndex = this.values.indexOf(value);
        let copiedArray = this.values.slice();

        if (valueIndex > -1) {
            //Remove
            copiedArray.splice(valueIndex, 1);
        } else {
            //Add
            copiedArray.push(value)
        }

        if (!this.IsMultiSelectMode && copiedArray.length == 2) {
            //Keep array size to 1 if this is a single select
            copiedArray.splice(0, 1);
        }

        this.$emit("update:values", copiedArray);
        this.$emit("Change"); //Useful for tacking on other callbacks

        if (!this.IsMultiSelectMode) {
            //Close selection modal for single select once a value is chosen
            this.ToggleIsDisplayingSelectModal(false);
        }
    }
}
